import React from 'react'
import { Embedly } from '../embedly'
import { NegotiationToolbar } from './negotiation-toolbar'
import { Tagline } from './tagline'

export function VideoHomeCover({ settings, locale }) {
  return (
    <div className="c-row c-row--alpha c-row--collapsed c-row--centered">
      <div className="o-container">
        <div className="o-grid o-grid--gutter">
          <div className="o-grid__item u-pt-delta">
            <div className="c-video">
              <Embedly
                url={settings.homepage_cover_video}
                apiKey="1002736901964502af4dba8505057cf1"
              />
            </div>
          </div>
          <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
            <Tagline settings={settings} locale={locale} />
            <NegotiationToolbar />
          </div>
        </div>
      </div>
    </div>
  )
}
